/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { graphql } from "gatsby"
import chunk from "lodash/chunk"
import kebabCase from "lodash/kebabCase"
import { Flex } from "@theme-ui/components"

import {
  getContentfulPageSectionListData,
  renderLineBreak,
  findBySlug,
} from "../helpers"

import Carousel from "../components/carousel"
import Column from "../components/column"
import Container from "../components/container"
import ContentCard from "../components/content-card"
import CTA from "../components/cta"
import Hero from "../components/hero"
import Page from "../components/page"
import ProjectCard from "../components/project-card"
import ProjectUnique from "../components/project-unique"
import Row from "../components/row"
import Text from "../components/text"

const IndexPage = ({ data }) => {
  const fullWidthList = [true, true, true]
  const renderHeroSection = () => {
    const heroSectionData = getContentfulPageSectionListData({
      sectionList: data.contentfulPage.sectionList,
      sectionSlug: "page-section-why-prefab-hero",
    })

    return (
      <Container fullWidth={fullWidthList}>
        <Row noGutters={fullWidthList}>
          <Column noGutters={fullWidthList}>
            <Carousel>
              {heroSectionData.heroList.map(heroData => (
                <Hero
                  key={kebabCase(heroData.title)}
                  background={
                    heroData.video
                      ? {
                          type: "video",
                          source: {
                            video: { file: { url: heroData.video } },
                            poster: { file: { url: heroData.background.url } },
                          },
                        }
                      : {
                          type: "image",
                          source: {
                            desktopImage: {
                              file: { url: heroData.background.url },
                            },
                          },
                        }
                  }
                  body={heroData.body}
                  tagline={heroData.tagline}
                  title={heroData.title}
                  type="primary"
                  verticalAlign={heroData.verticalAlign}
                />
              ))}
            </Carousel>
          </Column>
        </Row>
      </Container>
    )
  }
  const renderPlantPrefabDescriptionSection = () => {
    const plantPrefabDescriptionData = getContentfulPageSectionListData({
      sectionList: data.contentfulPage.sectionList,
      sectionSlug: "page-section-plant-prefab-description",
    })

    return (
      <Container customStyle={{ py: theme => theme.spacing.vertical.lg }}>
        <Row>
          <Column size={[12, 12, 6]}>
            <Text type="h2">
              {plantPrefabDescriptionData.titleList.map(titleLine => {
                const text = renderLineBreak(titleLine.text)

                return titleLine.color ? (
                  <span
                    sx={{ color: titleLine.color }}
                    key={kebabCase(titleLine.text)}
                  >
                    {text}
                  </span>
                ) : (
                  <React.Fragment key={kebabCase(titleLine.text)}>
                    {text}
                  </React.Fragment>
                )
              })}
            </Text>
          </Column>
          <Column
            customStyle={{
              mt: theme => [
                theme.spacing.vertical.md,
                theme.spacing.vertical.md,
                0,
              ],
            }}
            size={[12, 12, 6]}
          >
            <Text>{renderLineBreak(plantPrefabDescriptionData.body)}</Text>
            <div sx={{ mt: theme => theme.spacing.vertical.md }}>
              <CTA link={plantPrefabDescriptionData.cta.link} type="secondary">
                {plantPrefabDescriptionData.cta.text}
              </CTA>
            </div>
          </Column>
        </Row>
      </Container>
    )
  }
  const renderBenefitsSection = () => {
    const benefitsSectionData = getContentfulPageSectionListData({
      sectionList: data.contentfulPage.sectionList,
      sectionSlug: "page-section-benefits",
    })

    return (
      <div
        sx={{
          backgroundColor: theme => theme.colors.backgrounds.primary,
          py: theme => theme.spacing.vertical.lg,
        }}
      >
        <Container>
          <Row>
            {benefitsSectionData.contentCardList.map(contentCard => (
              <Column
                customStyle={{
                  mb: theme => [
                    theme.spacing.vertical.sm,
                    theme.spacing.vertical.sm,
                    0,
                  ],
                  ":last-child": { mb: 0 },
                }}
                key={kebabCase(contentCard.text)}
                size={[12, 12, null]}
              >
                <ProjectCard
                  background={contentCard.background}
                  text={contentCard.text}
                  sx={{ pb: "160%", height: 0 }}
                />
              </Column>
            ))}
          </Row>
          <Row customStyle={{ mt: theme => theme.spacing.vertical.lg }}>
            <Column size={[12, 12, 5]}>
              <Text type="h2">
                {renderLineBreak(benefitsSectionData.title)}
              </Text>
            </Column>
            <Column size={[12, 12, 7]}>
              <Text
                customStyle={{
                  mt: theme => [
                    theme.spacing.vertical.sm,
                    theme.spacing.vertical.sm,
                    0,
                  ],
                }}
              >
                {renderLineBreak(benefitsSectionData.body)}
              </Text>
              <div sx={{ mt: theme => theme.spacing.vertical.md }}>
                <CTA link={benefitsSectionData.cta.link} type="secondary">
                  {benefitsSectionData.cta.text}
                </CTA>
              </div>
            </Column>
          </Row>
        </Container>
      </div>
    )
  }
  const renderAudiencesSection = () => {
    const audiencesSectionData = getContentfulPageSectionListData({
      sectionList: data.contentfulPage.sectionList,
      sectionSlug: "page-section-audiences",
    })

    return (
      <Container
        customStyle={{
          py: theme => [
            theme.spacing.vertical.md,
            theme.spacing.vertical.md,
            theme.spacing.vertical.xl,
          ],
        }}
      >
        <Row>
          <Column>
            <Text
              customStyle={{
                fontSize: [5, 5, 6],
                maxWidth: ["40%", "40%", "none"],
                textAlign: ["left", "left", "center"],
              }}
              type="h2"
            >
              {audiencesSectionData.title}
            </Text>
          </Column>
        </Row>
        <Row
          customStyle={{
            justifyContent: "center",
            pt: theme => [
              theme.spacing.vertical.md,
              theme.spacing.vertical.md,
              theme.spacing.vertical.lg,
            ],
          }}
        >
          {audiencesSectionData.tileList.map(tileData => (
            <Column
              customStyle={{
                mb: theme => [
                  theme.spacing.vertical.sm,
                  theme.spacing.vertical.sm,
                  0,
                ],
                ":last-child": { mb: 0 },
              }}
              size={[12, 12, 4, 3]}
              key={kebabCase(tileData.text)}
            >
              <Flex
                sx={{
                  alignItems: ["flex-start", "flex-start", "center"],
                  flexDirection: ["row", "row", "column"],
                  height: "100%",
                  justifyContent: ["flex-start", "flex-start", "center"],
                }}
              >
                <div
                  sx={{
                    backgroundImage: `url(${tileData.imageURL})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    height: 70,
                    width: 70,
                  }}
                />
                <div
                  sx={{ ml: [4, 4, 0], textAlign: ["left", "left", "center"] }}
                >
                  <Text
                    customStyle={{
                      fontSize: [3, 3, 5],
                      mt: theme => [0, 0, theme.spacing.vertical.sm],
                    }}
                    variant="h3"
                  >
                    {tileData.text}
                  </Text>
                  <div sx={{ mt: theme => [2, 2, theme.spacing.vertical.sm] }}>
                    <CTA
                      link={tileData.cta.link}
                      linkType="internal"
                      type="secondary"
                    >
                      {tileData.cta.text}
                    </CTA>
                  </div>
                </div>
              </Flex>
            </Column>
          ))}
        </Row>
      </Container>
    )
  }
  const renderFeaturedProjectsSection = () => {
    const featuredProjectsSectionData = getContentfulPageSectionListData({
      sectionList: data.contentfulPage.sectionList,
      sectionSlug: "page-section-featured-projects",
    })
    const renderProjectCard = (projectIndex, type) => {
      const projectList = data.allContentfulProject.edges

      return projectList[projectIndex] ? (
        <ProjectCard
          background={projectList[projectIndex].node.projectImages[0].fluid.src}
          text={[
            projectList[projectIndex].node.projectTitle.projectTitle,
          ].concat(
            projectList[projectIndex].node.projectLocation.projectLocation
          )}
          type={type || "primary"}
        />
      ) : null
    }

    return (
      <React.Fragment>
        <Container>
          <Row>
            <Column>
              <Text type="h2">{featuredProjectsSectionData.title}</Text>
              <div
                sx={{
                  mt: theme => theme.spacing.vertical.md,
                  mb: theme => theme.spacing.vertical.lg,
                }}
              >
                <CTA
                  link={featuredProjectsSectionData.cta.link}
                  type="secondary"
                >
                  {featuredProjectsSectionData.cta.text}
                </CTA>
              </div>
            </Column>
          </Row>
        </Container>
        <Container customStyle={{ pb: 5 }} fullWidth={fullWidthList}>
          <Row noGutters={fullWidthList}>
            <Column size={[12, 12, 6]}>
              <Row>
                <Column customStyle={{ pr: [2, 2, 1], pl: 2 }}>
                  {renderProjectCard(0)}
                </Column>
              </Row>
              <Row customStyle={{ mt: 2 }} size={[12, 12, 6]}>
                <Column customStyle={{ mb: [2, 2, 0], pr: [2, 2, 1], pl: 2 }}>
                  {renderProjectCard(1, "secondary")}
                </Column>
                <Column
                  customStyle={{ pr: [2, 2, 1], pl: [2, 2, 1] }}
                  size={[12, 12, 6]}
                >
                  {renderProjectCard(2, "secondary")}
                </Column>
              </Row>
            </Column>
            <Column>
              <Row>
                <Column
                  customStyle={{
                    mt: [2, 2, 0],
                    mb: [2, 2, 0],
                    pl: 1,
                    pr: [2, 2, 1],
                  }}
                  size={[12, 12, 6]}
                >
                  {renderProjectCard(3, "secondary")}
                </Column>
                <Column customStyle={{ pl: 1, pr: 2 }}>
                  {renderProjectCard(4, "secondary")}
                </Column>
              </Row>
              <Row customStyle={{ mt: 2 }}>
                <Column customStyle={{ pl: 1, pr: 2 }}>
                  {renderProjectCard(5)}
                </Column>
              </Row>
            </Column>
          </Row>
        </Container>
      </React.Fragment>
    )
  }
  const renderAwardsFeaturedInSection = () => {
    const awardsFeaturedInSectionData = getContentfulPageSectionListData({
      sectionList: data.contentfulPage.sectionList,
      sectionSlug: "page-section-awards-featured-in",
    })

    return (
      <Container customStyle={{ py: theme => theme.spacing.vertical.xl }}>
        {awardsFeaturedInSectionData.contentRowList.map(rowData => (
          <React.Fragment key={rowData.id}>
            <Row customStyle={{ mb: theme => theme.spacing.vertical.md }}>
              <Column>
                <Text customStyle={{ textAlign: "center" }} type="h2">
                  {rowData.title}
                </Text>
              </Column>
            </Row>
            <Row
              customStyle={{
                alignItems: "center",
                mb: theme => theme.spacing.vertical.lg,
                ":last-child": { mb: 0 },
              }}
            >
              {rowData.columnList.map(columnData => (
                <Column
                  customStyle={{
                    alignItems: [
                      "flex-start",
                      "flex-start",
                      "flex-start",
                      "center",
                    ],
                    mb: [
                      theme => theme.spacing.vertical.sm,
                      theme => theme.spacing.vertical.sm,
                      theme => theme.spacing.vertical.sm,
                      0,
                    ],
                    ":last-child": { mb: 0 },
                  }}
                  size={[12, 12, 12, null]}
                  key={columnData.id}
                >
                  <Flex sx={{ alignItems: "center" }}>
                    <img
                      sx={{ maxWidth: 140 }}
                      src={columnData.imageURL}
                      alt=""
                    />
                    {columnData.text && (
                      <Text customStyle={{ fontWeight: "heading", ml: 3 }}>
                        {renderLineBreak(columnData.text)}
                      </Text>
                    )}
                  </Flex>
                </Column>
              ))}
            </Row>
          </React.Fragment>
        ))}
      </Container>
    )
  }
  const renderLivinghomesModelsSection = () => {
    const livinghomesModelsSectionData = getContentfulPageSectionListData({
      sectionList: data.contentfulPage.sectionList,
      sectionSlug: "page-section-livinghomes-models",
    })
    const renderContentCard = standardHome => (
      <ContentCard
        baths={standardHome.baths}
        beds={standardHome.beds}
        imageList={[standardHome.images[0]]}
        squareFootage={standardHome.squareFootage}
        title={standardHome.name}
        titleLink={
          standardHome.model
            ? {
                to: `/models/${standardHome.model.slug}`,
                type: "internal",
              }
            : null
        }
        key={standardHome.id}
      />
    )
    const getMobileCarouselContent = () => {
      const carouselContent = []

      data.allContentfulModelVariation.edges.forEach(standardHome => {
        if (standardHome.node.images && carouselContent.length < 4) {
          carouselContent.push(renderContentCard(standardHome.node))
        }
      })

      return carouselContent
    }
    const getDesktopCarouselContent = () => {
      const carouselContent = []

      chunk(
        data.allContentfulModelVariation.edges.filter(
          standardHome => standardHome.node.images
        ),
        2
      ).forEach((standardHome, index) => {
        if (standardHome.length === 2) {
          const getColumnSize = columnSize => {
            return {
              flexBasis: `${columnSize * 100}%`,
              maxWidth: `${columnSize * 100}%`,
            }
          }

          carouselContent.push(
            <div
              sx={{ display: "flex", height: "100%" }}
              key={standardHome[0].node.id}
            >
              <div sx={getColumnSize(index % 2 === 0 ? 1 / 3 : 2 / 3)}>
                {renderContentCard(standardHome[0].node)}
              </div>
              <div
                sx={{
                  pl: theme => theme.spacing.horizontal,
                  ...getColumnSize(index % 2 === 0 ? 2 / 3 : 1 / 3),
                }}
              >
                {renderContentCard(standardHome[1].node)}
              </div>
            </div>
          )
        } else if (standardHome.length === 1 && standardHome[0].node.images) {
          carouselContent.push(renderContentCard(standardHome[0].node))
        }
      })

      return carouselContent
    }

    return (
      <Container customStyle={{ pb: theme => theme.spacing.vertical.lg }}>
        <Row>
          {livinghomesModelsSectionData.titleList.map((titleData, index) => (
            <Column
              customStyle={{
                mb: theme => [
                  theme.spacing.vertical.sm,
                  theme.spacing.vertical.sm,
                  0,
                ],
                ":last-child": { mb: 0 },
              }}
              size={index ? [12, 12, 8] : [12, 12, 4]}
              key={kebabCase(titleData.text)}
            >
              <Text type="h2">{renderLineBreak(titleData.text)}</Text>
            </Column>
          ))}
        </Row>
        <Row customStyle={{ mt: theme => theme.spacing.vertical.lg }}>
          <Column customStyle={{ display: ["block", "block", "none"] }}>
            <Carousel type="quaternary">{getMobileCarouselContent()}</Carousel>
          </Column>
          <Column customStyle={{ display: ["none", "none", "block"] }}>
            <Carousel type="quaternary">{getDesktopCarouselContent()}</Carousel>
          </Column>
        </Row>
      </Container>
    )
  }
  const renderProjectFeaturesSection = () => {
    const eachProjectIsUniqueBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "page-blade-pattern-each-project-is-unique",
    })

    return (
      <ProjectUnique data={eachProjectIsUniqueBladePatternData}></ProjectUnique>
    )
    /* // TODO: Abstract into component since this is reused inside Advantages page.
    return (
      <div
        sx={{
          backgroundColor: "#efefef",
          backgroundImage: `url(${validationSectionData.background.url})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <Container
          customStyle={{
            pt: theme => [
              theme.spacing.vertical.md,
              theme.spacing.vertical.md,
              300,
            ],
            pb: theme => [
              theme.spacing.vertical.md,
              theme.spacing.vertical.md,
              theme.spacing.vertical.lg,
            ],
          }}
        >
          <Row
            customStyle={{
              display: ["block", "block", "none"],
              mb: theme => theme.spacing.vertical.md,
            }}
          >
            <Column>
              <Text customStyle={{ color: "white" }} type="h2">
                {renderLineBreak(validationSectionData.title)}
              </Text>
            </Column>
          </Row>
          <Row>
            {validationSectionData.contentList.map(contentData => (
              <Column
                customStyle={{
                  mb: theme => [
                    theme.spacing.vertical.md,
                    theme.spacing.vertical.md,
                    0,
                  ],
                  ":last-child": { mb: 0 },
                }}
                size={[12, 12, 4, 4, 3]}
                key={kebabCase(contentData.title)}
              >
                <div>
                  <Flex sx={{ alignItems: "center" }}>
                    <div
                      sx={{
                        backgroundImage: `url(${contentData.imageURL})`, // TODO: Placeholder, update with img tag once real content is defined.
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        border: "2px solid #ccc",
                        flexShrink: 0,
                        height: 45,
                        mr: 15,
                        width: 45,
                      }}
                    />
                    <Text customStyle={{ color: "white" }} type="h3">
                      {contentData.title}
                    </Text>
                  </Flex>
                  <Text
                    customStyle={{
                      color: "white",
                      mt: theme => [
                        theme.spacing.vertical.sm,
                        theme.spacing.vertical.sm,
                        theme.spacing.vertical.md,
                      ],
                    }}
                  >
                    {renderLineBreak(contentData.body)}
                  </Text>
                  <div
                    sx={{
                      mt: theme => [
                        theme.spacing.vertical.sm,
                        theme.spacing.vertical.sm,
                        theme.spacing.vertical.md,
                      ],
                    }}
                  >
                    <CTA link={contentData.cta.link} type="secondary">
                      {contentData.cta.text}
                    </CTA>
                  </div>
                </div>
              </Column>
            ))}
          </Row>
          <Row
            customStyle={{
              mt: theme => theme.spacing.vertical.lg,
              display: ["none", "none", "block"],
            }}
          >
            <Column>
              <Text customStyle={{ color: "white" }} type="h2" variant="h1">
                {renderLineBreak(validationSectionData.title)}
              </Text>
            </Column>
          </Row>
        </Container>
      </div>
    ) */
  }

  return (
    <Page data={data.contentfulPage}>
      {renderHeroSection()}
      {renderPlantPrefabDescriptionSection()}
      {renderBenefitsSection()}
      {renderAudiencesSection()}
      {renderFeaturedProjectsSection()}
      {renderAwardsFeaturedInSection()}
      {renderLivinghomesModelsSection()}
      {renderProjectFeaturesSection()}

      <Container>
        <Row customStyle={{ py: 6 }}>
          <Column>
            <h2 sx={{ m: 0, textAlign: "center" }}>Events Placeholder</h2>
          </Column>
        </Row>
      </Container>
    </Page>
  )
}

export default IndexPage

export const query = graphql`
  query indexPageQuery {
    contentfulPage(slug: { eq: "why-prefab" }) {
      ...PageData
      ...BladePatternData
    }

    allContentfulModelVariation(sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          baths
          beds
          id
          images {
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid_withWebp
            }
            id
            title
          }
          model {
            id
            slug
          }
          name
          squareFootage
        }
      }
    }

    allContentfulProject(limit: 6, sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          projectImages {
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid_withWebp
            }
            id
            title
          }
          projectLocation {
            projectLocation
          }
          projectTitle {
            projectTitle
          }
        }
      }
    }
  }
`
